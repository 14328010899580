import config from "@/../config";
import http from "@/api/http";
import { getLocalParam } from "@/vendors/helpers";

export default {
  crearDisposicion(data, onSuccess, onError) {
    return http
      .post(`${config.host}/${config.version}/disposicion/simular`, data)
      .then(onSuccess)
      .catch(onError);
  },
  editarDisposicion(data, onSuccess, onError) {
    return http
      .patch(
        `${config.host}/${config.version}/disposicion/simular/${data.id}`,
        data
      )
      .then(onSuccess)
      .catch(onError);
  },
  getDisposiciones(tipo, filtros, pagina, linea, onSuccess, onError) {
    if (tipo == null) {
      tipo = "";
    }
    return http
      .get(`${config.host}/${config.version}/disposicion/${tipo}`, {
        params: {
          filtros: JSON.stringify(filtros),
          por_pagina: getLocalParam("paginador") || 20,
          pagina: pagina,
          linea_id: linea
        }
      })
      .then(onSuccess)
      .catch(onError);
  },
  getDisposicionDetalle(id, onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/disposicion/id/${id}`)
      .then(onSuccess)
      .catch(onError);
  },
  getTablaAmortizacion(id, onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/disposicion/amortizacion/${id}`)
      .then(onSuccess)
      .catch(onError);
  },
  getTablaAmortizacionGrupal(id, onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/disposicion/amortizacion_grupal/${id}`)
      .then(onSuccess)
      .catch(onError);
  },
  changeProspectoEspera(data, onSuccess, onError) {
    return http
      .patch(
        `${config.host}/${config.version}/disposicion/status/prospecto_espera/${data.id}`,
        data
      )
      .then(onSuccess)
      .catch(onError);
  },
  changeProspectoEsperaMasivo(data, onSuccess, onError) {
    return http
      .patch(
        `${config.host}/${config.version}/disposicion/status/masivo/prospecto_espera`,
        data
      )
      .then(onSuccess)
      .catch(onError);
  },
  changeEsperaAutorizado(data, onSuccess, onError) {
    return http
      .patch(
        `${config.host}/${config.version}/disposicion/status/espera_autorizado/${data.id}`,
        data
      )
      .then(onSuccess)
      .catch(onError);
  },
  changeEsperaAutorizadoMasivo(data, onSuccess, onError) {
    return http
      .patch(
        `${config.host}/${config.version}/disposicion/status/masivo/espera_autorizado`,
        data
      )
      .then(onSuccess)
      .catch(onError);
  },
  changeAutorizadoEntregadoActivo(data, onSuccess, onError) {
    return http
      .patch(
        `${config.host}/${config.version}/disposicion/status/autorizado_entregado_activo`,
        data
      )
      .then(onSuccess)
      .catch(onError);
  },
  changeAutorizadoEntregadoActivoMasivo(data, onSuccess, onError) {
    return http
      .patch(
        `${config.host}/${config.version}/disposicion/status/masivo/autorizado_entregado_activo`,
        data
      )
      .then(onSuccess)
      .catch(onError);
  },
  changeAutorizadoEntregadoPasivo(data, onSuccess, onError) {
    return http
      .patch(
        `${config.host}/${config.version}/disposicion/status/autorizado_entregado_pasivo`,
        data
      )
      .then(onSuccess)
      .catch(onError);
  },
  changeAutorizadoEntregadoPasivoMasivo(data, onSuccess, onError) {
    return http
      .patch(
        `${config.host}/${config.version}/disposicion/status/masivo/autorizado_entregado_pasivo`,
        data
      )
      .then(onSuccess)
      .catch(onError);
  },
  declinar(data, onSuccess, onError) {
    return http
      .patch(
        `${config.host}/${config.version}/disposicion/status/declinado/${data.id}`,
        {
          motivo: data.motivo
        }
      )
      .then(onSuccess)
      .catch(onError);
  },
  archivar(data, onSuccess, onError) {
    return http
      .patch(
        `${config.host}/${config.version}/disposicion/status/archivado/${data.id}`,
        {
          motivo: data.motivo
        }
      )
      .then(onSuccess)
      .catch(onError);
  },
  eliminar(data, onSuccess, onError) {
    return http
      .delete(
        `${config.host}/${config.version}/disposicion/${data.id}`
      )
      .then(onSuccess)
      .catch(onError);
  },
  simularTablaAmortizacion(data, onSuccess, onError) {
    return http
      .post(`${config.host}/${config.version}/disposicion/simular_tabla`, data)
      .then(onSuccess)
      .catch(onError);
  },
  ligarArchivos(data, onSuccess, onError) {
    return http
      .post(
        `${config.host}/${config.version}/disposicion/ligar_docs/${data.id}`,
        data
      )
      .then(onSuccess)
      .catch(onError);
  },
  desligarArchivos(data, onSuccess, onError) {
    return http
      .delete(
        `${config.host}/${config.version}/disposicion/desligar_docs/${data.disposicion_id}/${data.file_id}`
      )
      .then(onSuccess)
      .catch(onError);
  },
  getDocumentos(id, onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/disposicion/archivos/${id}`)
      .then(onSuccess)
      .catch(onError);
  },
  getBitacora(id, onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/disposicion/bitacora/${id}`)
      .then(onSuccess)
      .catch(onError);
  },
  agregarComentario(data, onSuccess, onError) {
    return http
      .post(
        `${config.host}/${config.version}/disposicion/agregar_comentario/${data.disposicion_id}`,
        data
      )
      .then(onSuccess)
      .catch(onError);
  },
  getOperacionesDiarias(params, onSuccess, onError) {
    let array = params.split("&");
    let disposicion_id = null;
    array.forEach(el => {
      if (el.includes("id=")) {
        disposicion_id = el.split("id=")[1];
      }
    });
    return http
      .get(
        `${config.host}/${config.version}/disposicion/operaciones_diarias/${disposicion_id}/?${params}`
      )
      .then(onSuccess)
      .catch(onError);
  },
  getCredito(params, onSuccess, onError) {
    return http
      .get(
        `${config.host}/${config.version}/credito/${params.id}?fecha_contable=${params.fecha}`
      )
      .then(onSuccess)
      .catch(onError);
  },
  getPagos(disposicion_id, pagina = 1, onSuccess, onError) {
    return http
      .get(
        `${config.host}/${config.version}/credito/${disposicion_id}/desglose_pagos`, {
          params: {
            por_pagina: 20,
            pagina,
          }
      })
      .then(onSuccess)
      .catch(onError);
  },
  getPagosAccesorios(disposicion_id, pagina = 1, onSuccess, onError) {
    return http
      .get(
        `${config.host}/${config.version}/credito/${disposicion_id}/desglose_pagos_accesorios`, {
          params: {
            por_pagina: 20,
            pagina,
          }
      })
      .then(onSuccess)
      .catch(onError);
  },
  getSupervisiones(disposicion_id, onSuccess, onError) {
    return http
      .get(
        `${config.host}/${config.version}/disposicion/${disposicion_id}/supervisiones`
      )
      .then(onSuccess)
      .catch(onError);
  },
  getGarantias(disposicion_id, onSuccess, onError) {
    return http
      .get(
        `${config.host}/${config.version}/disposicion/${disposicion_id}/garantias`
      )
      .then(onSuccess)
      .catch(onError);
  },
  asignarGarantia(data, onSuccess, onError) {
    return http
      .post(
        `${config.host}/${config.version}/disposicion/${data.disposicion_id}/asignar_garantia_fondeador`,
        data
      )
      .then(onSuccess)
      .catch(onError);
  },
  ligarDisposicionPasiva(data, onSuccess, onError) {
    return http
      .patch(
        `${config.host}/${config.version}/disposicion/${data.disposicion_activa_id}/fondear_disposicion`,
        data
      )
      .then(onSuccess)
      .catch(onError);
  },
  getPasivas(disposicion_id, onSuccess, onError) {
    return http
      .get(
        `${config.host}/${config.version}/disposicion/${disposicion_id}/pasivas`
      )
      .then(onSuccess)
      .catch(onError);
  },
  getActivas(disposicion_id, onSuccess, onError) {
    return http
      .get(
        `${config.host}/${config.version}/disposicion/${disposicion_id}/activas`
      )
      .then(onSuccess)
      .catch(onError);
  },
  getFuentesFondeo(disposicion_id, onSuccess, onError) {
    return http
      .get(
        `${config.host}/${config.version}/credito/${disposicion_id}/fuente-fondeo`
      )
      .then(onSuccess)
      .catch(onError);
  },
  asignarFuenteFondeo(data, onSuccess, onError) {
    return http
      .patch(
        `${config.host}/${config.version}/credito/${data.disposicion_id}/fuente-fondeo`,
        data
      )
      .then(onSuccess)
      .catch(onError);
  },
  cambiarTasaInteresAjeno(data, onSuccess, onError) {
    return http
      .patch(
        `${config.host}/${config.version}/disposicion/${data.id}/interes_ordinario_ra`,
        data
      )
      .then(onSuccess)
      .catch(onError);
  },
  setCastigo(data, onSuccess, onError) {
    return http
      .post(
        `${config.host}/${config.version}/credito/${data.id}/castigos`,
        data
      )
      .then(onSuccess)
      .catch(onError);
  },
  getCastigos(disp_id, onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/credito/${disp_id}/castigos`)
      .then(onSuccess)
      .catch(onError);
  },
  printEstadoCuenta(data, onSuccess, onError) {
    return http
      .get(
        `${config.host}/${config.version}/disposicion/${data.disposicion_id}/print_estado_cuenta?fecha_inicial=${data.fecha_inicial}&fecha_final=${data.fecha_final}`
      )
      .then(onSuccess)
      .catch(onError);
  },
  printTablaAmortizaciones(data, onSuccess, onError) {
    return http
      .get(
        `${config.host}/${config.version}/disposicion/${data.disposicion_id}/print_amortizaciones?sinDetalles=${data.sinDetalles ? 1 : 0}&soloSaldos=${data.soloSaldos ? 1 : 0}`
      )
      .then(onSuccess)
      .catch(onError);
  },
  printSimulacion(data, onSuccess, onError) {
    return http
      .post(
        `${config.host}/${config.version}/disposicion/print_amortizaciones?sinDetalles=${data.sinDetalles ? 1 : 0}&soloSaldos=${data.soloSaldos ? 1 : 0}`,
        data
      )
      .then(onSuccess)
      .catch(onError);
  },
  printPagare(data, onSuccess, onError) {
    return http
      .get(
        `${config.host}/${config.version}/disposicion/${data.disposicion_id}/print_pagare`
      )
      .then(onSuccess)
      .catch(onError);
  },
  toggleRefinanciamientoVencido(disposicion_id, onSuccess, onError) {
    return http
      .patch(
        `${config.host}/${config.version}/credito/${disposicion_id}/refinanciamiento_vencido`
      )
      .then(onSuccess)
      .catch(onError);
  },
  getRefinanciamientoVencido(disposicion_id, onSuccess, onError) {
    return http
      .get(
        `${config.host}/${config.version}/credito/${disposicion_id}/refinanciamiento_vencido`
      )
      .then(onSuccess)
      .catch(onError);
  },
  generarCuentaPrima(data, onSuccess, onError) {
    return http
      .post(
        `${config.host}/${config.version}/disposicion/${data.disposicion_id}/garantia_fondeador`,
        { garantia: data.garantia }
      )
      .then(onSuccess)
      .catch(onError);
  },
  getCuentas(disposicion_id, onSuccess, onError) {
    return http
      .get(
        `${config.host}/${config.version}/disposicion/${disposicion_id}/cuentas`
      )
      .then(onSuccess)
      .catch(onError);
  },
  getCuentasFinanciadas(disposicion_id, onSuccess, onError) {
    return http
      .get(
        `${config.host}/${config.version}/cuenta_financiada/disposicion/${disposicion_id}`
      )
      .then(onSuccess)
      .catch(onError);
  },
  financiarCuenta(data, onSuccess, onError) {
    return http
      .post(
        `${config.host}/${config.version}/cuenta_financiada/${data.cuenta_id}`,
        data
      )
      .then(onSuccess)
      .catch(onError);
  },
  asignarActivo(data, onSuccess, onError) {
    return http
      .patch(
        `${config.host}/${config.version}/credito/asignar_activo_pasivo/${data.pasiva_id}`,
        { 
          credito_activo_id: data.credito_activo_id,
          valor_fondeo: data.valor_fondeo
        }
      )
      .then(onSuccess)
      .catch(onError);
  },
  asignarGarantiaExistente(data, onSuccess, onError) {
    return http
      .patch(
        `${config.host}/${config.version}/disposicion/${data.disposicion_id}/garantia_fondeador`,
        data
      )
      .then(onSuccess)
      .catch(onError);
  },
  obtenerReferenciaBancariaDisposicion(disposicion_id, onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/disposicion/${disposicion_id}/referencia_bancaria`)
      .then(onSuccess)
      .catch(onError);
  },
  crearReferenciaBancariaDisposicion(data, onSuccess, onError) {
    return http
      .post(
        `${config.host}/${config.version}/disposicion/${data.disposicion_id}/referencia_bancaria`,
        { referencia_bancaria: data.referencia_bancaria }
      )
      .then(onSuccess)
      .catch(onError);
  },
  borrarReferenciaBancariaDisposicion(data, onSuccess, onError) {
    return http
      .delete(
        `${config.host}/${config.version}/disposicion/${data.disposicion_id}/referencia_bancaria/${data.referencia_bancaria}`
      )
      .then(onSuccess)
      .catch(onError);
  },
  crearCreditoBursatilizado(credito_id, onSuccess, onError) {
    return http
      .patch(`${config.host}/${config.version}/credito/${credito_id}/bursatilizado`)
      .then(onSuccess)
      .catch(onError);
  },
  borrarCreditoBursatilizado(credito_id, onSuccess, onError) {
    return http
      .delete(
        `${config.host}/${config.version}/credito/${credito_id}/bursatilizado`
      )
      .then(onSuccess)
      .catch(onError);
  },
  agregarCuentaBancoAcreditado(data, onSuccess, onError) {
    return http
      .post(
        `${config.host}/${config.version}/disposicion/${data.disposicion_id}/cuenta_banco_acreditado`,
        { cuenta_banco_id: data.cuenta_banco_id }
      )
      .then(onSuccess)
      .catch(onError);
  },
};
